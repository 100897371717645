import adminApi from "./admin.api";
import qs from 'qs'

export default {
    /**
     * Consulta y filtra registros de listas
     * @param {Object} query
     * @returns {Promise}
     */
    listas(query= {}) {
        return adminApi.api.get(`${adminApi.baseURL}/listas?${qs.stringify(query)}`)
    },

    /**
     * Obtiene una lista con sus opciones
     * @param {int} lista
     * @returns {Promise}
     */
    lista(lista) {
        return adminApi.api.get(`${adminApi.baseURL}/listas/${lista}`)
    },

    /**
     * Editar lista, retorna lista que se editó con todas sus opciones actualizados
     * @param lista: id de la lista que se va a editar
     * @param params: parametros o campos a editar eje: {valor: 'valor editado'}
     * @returns {Promise}
     */
    editarLista(lista, params) {
        return adminApi.api.put(`${adminApi.baseURL}/listas/${lista}`, params)
    },

    /**
     * Agregar opción a lista, retorna lista a la que se agregó el nuevo opción con listado de opciones actualizadas
     * @param lista: id de la lista a la que se le va agregar la opción
     * @param params: parametros o campos de opción eje: {valor: 'nueva opcion'}
     * @returns {Promise}
     */
    agregarOpcion(lista, params){
        return adminApi.api.post(`${adminApi.baseURL}/listas/${lista}/opciones`, params)
    },

    /**
     * Editar opción de lista, retorna lista del opción que se editó con todos sus opciones actualizados
     * @param lista: id de la lista
     * @param opcion: id de opción
     * @param params: parametros o campos a editar eje: {valor: 'valor editado'}
     * @returns {Promise}
     */
    editarOpcion(lista, opcion, params){
        return adminApi.api.put(`${adminApi.baseURL}/listas/${lista}/opciones/${opcion}`, params)
    },

    /**
     * Eliminar opción de lista, retorna la lista de la opción que se eliminó con su lista de opciones acualizada
     * @param lista: id de la lista
     * @param opcion: id de opción
     * @returns {Promise}
     */
    eliminarOpcion(lista, opcion){
        return adminApi.api.delete(`${adminApi.baseURL}/listas/${lista}/opciones/${opcion}`)
    }

}
