import Vue from "vue";
import axios from '@/plugins/axios'
/**
 * baseUrl de api de admin cobranzas
 * @type {string}
 */
const baseURL = process.env.VUE_APP_API_COBRANZAS;

/**
 * Interceptor api admin cobranza
 */
axios.interceptors.response.use(response => {
    return response;
}, async(error) => {
  let response = error.response;
  if ( response.config.url.includes(baseURL)){
    let message = !response.data.message ? `Error inesperado: ${response.data.code}` : response.data.message;
    Vue.prototype.$helper.showMessage('Error',message,'error','alert');
    return Promise.reject(error);
  }
});

export default {
  api: axios,
  baseURL
}

